import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/layout"
import NewsTeaser from "../../components/news-teaser"

export default function NewsPage({ data }) {
  const posts = data.news.edges
  return (
    <Layout showSubnav="true">   
      <div className="bg-white relative mx-auto max-w-xl lg:max-w-7xl px-4 pb-16 pt-32 sm:px-6 lg:px-8 lg:pt-64 lg:pb-28">
        <div className="divide-y-2 divide-gray-200">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-sky-900 sm:text-4xl">News</h2>
          </div>
          <div className="mt-6 lg:pt-16 grid gap-16 pt-10 lg:grid-cols-3 lg:gap-x-10 lg:gap-y-12">
            {posts.map(post => (
              <NewsTeaser post={post} />    
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}news/`
  return (
    <>
      <meta name="description" content="Catch up on the latest news about DEC Housing." />
      <title>News | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export const query = graphql`
  query NewsPageQuery {
    news: allNodeNews(filter: {status: {eq: true}}, sort: {fields: created, order: DESC}) {
      edges {
        node {
          ...NewsTeaserFields
        }
      }
    }
  }
`